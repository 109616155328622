@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.notification-dropdown__message-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: #31353d;

  .skeleton-loading {
    height: 15px;
  }
}

.center {
  text-align: center;
}

.notification-dropdown__message-date {
  color: #9c9c9c;
}

.notification-dropdown__message-list:first-of-type {
  padding-top: 0;
}

.notification-dropdown__message-list:not(:last-of-type) {
  border-bottom: 1px solid $platinum;
}

.notification-dropdown__no-messages {
  padding: 3rem 1rem;
}

.notification-dropdown__view-all {
  padding: 1rem 1rem 0;
}
